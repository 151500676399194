<template>
  <div id="app">
    <header v-if="$route.name === 'home'">
      <div id="header_container">
        <div id="inner_radius" />
        <div id="logo">
          <img :src="require('@/assets/logo.svg', )" />
        </div>
        <div id="nav_container">
          <nav>
            <a href="#accueil">
              <button class="active">Accueil</button>
            </a>
            <a href="#services">
              <button>Services</button>
            </a>
            <a href="#presentation">
              <button>Présentation</button>
            </a>
            <a href="#portfolio">
              <button>Réalisations</button>
            </a>
            <a
              v-if="false"
              href="/cv.pdf"
              target="_blank"
            >
              <button>CV</button>
            </a>
            <a
              href="#contact"
              id="contact_button"
            >
              <button>Me contacter</button>
            </a>
          </nav>
        </div>
      </div>
    </header>
    <router-view/>
    <footer>
      <p>
        ©{{new Date().getFullYear()}} Heckmann&nbsp;Développement - SIRET 91880454300018
        - Tous droits réservés -
        <router-link :to="{name: 'legals', }">Mentions&nbsp;légales</router-link>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@use "@/assets/styles/index.scss";
</style>
